import { ImgHTMLAttributes } from 'react';
import analyzeIconSvg from './analyze.icon.svg';

export type AnalyzeIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export function AnalyzeIcon(props: AnalyzeIconProps) {
  return <img {...props} src={analyzeIconSvg} alt="Analyze" />;
}
