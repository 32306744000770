import { Box, Button } from '@mui/material';
import { AppPlaceholder } from '@repo/ui/components';

export function ErrorRoute() {
  return (
    <Box zIndex={1} position="relative">
      <AppPlaceholder>
        <Box textAlign="center" py={8}>
          <Button component="a" href="/">
            Reload app
          </Button>
        </Box>
      </AppPlaceholder>
    </Box>
  );
}
