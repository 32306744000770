import { ImgHTMLAttributes } from 'react';
import splashmetricsLogo from './splashmetrics-logo.svg';

export type SplashmetricsLogoProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export function SplashmetricsLogo(props: SplashmetricsLogoProps) {
  return <img src={splashmetricsLogo} alt="Splashmetrics logo" {...props} />;
}
