import { ImgHTMLAttributes } from 'react';
import dashboardsIconSvg from './dashboards.icon.svg';

export type DashboardsIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export function DashboardsIcon(props: DashboardsIconProps) {
  return <img {...props} src={dashboardsIconSvg} alt="Dashboards" />;
}
