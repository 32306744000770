import { AppBar, Box, Toolbar } from '@mui/material';
import { useMemo } from 'react';

export interface PrimaryAppBarProps {
  leading?: React.ReactNode;
  brand?: React.ReactNode | null;
  trailing?: React.ReactNode;
}

export function PrimaryAppBar(props: PrimaryAppBarProps) {
  const { leading, trailing } = props;

  const brand = useMemo(() => {
    if (!props.brand) {
      return null;
    }

    if (typeof props.brand === 'string') {
      return (
        <Box
          component="img"
          height={{ xs: 40, sm: 48 }}
          src={props.brand}
          alt="logo"
        />
      );
    }

    return props.brand;
  }, [props.brand]);

  return (
    <AppBar color="default" position="sticky" sx={{ backgroundColor: '#fff' }}>
      <Toolbar>
        <Box flex={1}>{leading}</Box>
        {brand}
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="flex-end">
          {trailing}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
