import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { Drop } from '../../../../generated/graphql';
import {
  InfoPopoverButton,
  InfoPopoverButtonProps,
} from '../../info-popover/info-popover.button';

export type DropInfoPopoverProps = InfoPopoverButtonProps & {
  drop: Pick<Drop, 'description' | 'dataSourceNames'>;
};

export function DropInfoPopover(props: DropInfoPopoverProps) {
  const { drop, ...infoPopoverButtonProps } = props;

  const { palette } = useTheme();

  const { description, dataSourceNames } = drop;

  if (!description && !dataSourceNames?.length) {
    return null;
  }

  return (
    <InfoPopoverButton {...infoPopoverButtonProps}>
      <Stack spacing={2}>
        {description ? (
          <Typography variant="body2" whiteSpace="pre-wrap">
            {description}
          </Typography>
        ) : null}
        {description && dataSourceNames?.length ? (
          <Divider color={palette.grey[400]} />
        ) : null}
        {dataSourceNames?.length ? (
          <Typography variant="caption">
            <strong>Data Sources:</strong> {dataSourceNames.join(', ')}
          </Typography>
        ) : null}
      </Stack>
    </InfoPopoverButton>
  );
}
