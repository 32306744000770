import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useMemo } from 'react';
export function AppPosthogProvider(props: React.PropsWithChildren) {
  const options = useMemo(
    (): Partial<PostHogConfig> => ({
      api_host: import.meta.env.VITE_POSTHOG_API_HOST,
    }),
    [],
  );

  return (
    <PostHogProvider
      {...props}
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={options}
    />
  );
}
