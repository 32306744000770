import {
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeProvider,
} from '@mui/material';
import deepmerge from 'deepmerge';
import React, { useMemo } from 'react';
import { appThemeOptions } from './app.theme-provider';

export const managePaletteColor: PaletteColor = {
  light: '#ffc8ae',
  main: '#f1977f',
  dark: '#bc6852',
  contrastText: '#fff',
};

export function ManageThemeProvider(
  props: React.PropsWithChildren<Record<never, never>>,
) {
  const { children } = props;

  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(appThemeOptions, {
          palette: {
            secondary: managePaletteColor,
          },
        }),
      ),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
