import AnnouncementIcon from '@mui/icons-material/Announcement';
import type {} from '@mui/lab/themeAugmentation';
import {
  createTheme,
  CssBaseline,
  tabsClasses,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import React, { useMemo } from 'react';
import { babyBluePaletteColor } from './baby-blue.palette';

const defaultTheme = createTheme();

const palette = createPalette({
  background: {
    default: '#ecf0f1',
  },
  primary: { main: 'rgb(2, 119, 189)' },
  secondary: { main: '#0e76bc' },
});

export const appThemeOptions: ThemeOptions = {
  palette,
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled&:not(.Mui-error)': {
            color: palette.text.primary,
            WebkitTextFillColor: palette.text.primary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: '#fff',
          '&.Mui-disabled': {
            backgroundColor: babyBluePaletteColor.light,
          },
        },
        input: {
          '&.Mui-disabled': {
            color: palette.text.primary,
            WebkitTextFillColor: palette.text.primary,
          },
        },
      },
    },
    MuiRadio: {
      defaultProps: { size: 'small' },
    },
    MuiCheckbox: {
      defaultProps: { size: 'small' },
    },
    MuiTextField: {
      defaultProps: { size: 'small', minRows: 2 },
    },
    MuiSelect: {
      defaultProps: { size: 'small' },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { fontSize: '0.875rem' },
      },
    },
    MuiAppBar: {
      defaultProps: { elevation: 0 },
    },
    MuiPaper: {
      defaultProps: { elevation: 0, square: true },
    },
    MuiButton: {
      defaultProps: { variant: 'outlined', disableElevation: true },
      styleOverrides: {
        root: { textTransform: 'none', borderRadius: 0 },
      },
    },
    MuiLoadingButton: {
      defaultProps: { variant: 'outlined', disableElevation: true },
      styleOverrides: {
        root: { textTransform: 'none', borderRadius: 0 },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { textTransform: 'none', borderRadius: 0 },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
          [`& .${tabsClasses.indicator}`]: {
            display: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: 36,
          paddingTop: 8,
          paddingBottom: 8,
          '&.Mui-selected': {
            color: palette.primary.main,
            backgroundColor: palette.primary.contrastText,
          },
          '&.Mui-disabled': {
            // backgroundColor: palette.,
          },
          '&:not(.Mui-selected,.Mui-disabled)': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
          '&:not(:last-child)': {
            marginRight: defaultTheme.spacing(0.25),
          },
        },
      },
    },
    MuiLink: {
      defaultProps: { underline: 'hover' },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { fontSize: '0.875rem' },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <AnnouncementIcon />,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      },
    },
  },
};

export function AppThemeProvider(
  props: React.PropsWithChildren<Record<never, never>>,
) {
  const { children } = props;

  const theme = useMemo(() => createTheme(appThemeOptions), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
