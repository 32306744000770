import {
  CardHeader as MuiCardHeader,
  CardHeaderProps,
  styled,
} from '@mui/material';

const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  minHeight: 46,
  padding: theme.spacing(1, 2),
}));

export type FormCardHeaderProps = CardHeaderProps;

export function FormCardHeader(props: FormCardHeaderProps) {
  const { title, titleTypographyProps, ...cardHeaderProps } = props;

  return (
    <CardHeader
      {...cardHeaderProps}
      title={title}
      titleTypographyProps={{
        variant: 'body2',
        fontWeight: 'bold',
        ...titleTypographyProps,
      }}
    />
  );
}
