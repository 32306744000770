import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AppAuth0Provider } from './app-auth0.provider';
import { ErrorRoute } from './error.route';
import { AppPosthogProvider } from './app-posthog.provider';

export function RootRoute() {
  return (
    <Sentry.ErrorBoundary showDialog fallback={<ErrorRoute />}>
      <AppAuth0Provider>
        <AppPosthogProvider>
          <RootRoutePresentation />
        </AppPosthogProvider>
      </AppAuth0Provider>
    </Sentry.ErrorBoundary>
  );
}

function RootRoutePresentation() {
  const { user } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    gtag('set', { user_id: user?.sub });
  }, [user?.sub]);

  useEffect(() => {
    gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return <Outlet />;
}
