import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const callbackPathname = '/auth/callback';

export function AppAuth0Provider(
  props: React.PropsWithChildren<Record<never, never>>,
) {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      useRefreshTokens
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${callbackPathname}`,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      }}
      onRedirectCallback={(appState) => {
        const { pathname } = window.location;

        navigate(
          appState?.returnTo ||
            (pathname === callbackPathname ? '/' : pathname),
        );
      }}>
      {props.children}
    </Auth0Provider>
  );
}
