import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
  HexColorCode: { input: string; output: string; }
};

export type AbilityRule = {
  __typename?: 'AbilityRule';
  action: Array<Scalars['String']['output']>;
  fields: Maybe<Array<Scalars['String']['output']>>;
  inverted: Maybe<Scalars['Boolean']['output']>;
  reason: Maybe<Scalars['String']['output']>;
  subject: Array<Scalars['String']['output']>;
};

export type ActivateSplashInput = {
  id: Scalars['ID']['input'];
};

export type ActivateSplashPayload = {
  __typename?: 'ActivateSplashPayload';
  splash: Splash;
};

export type AddDropToMyDashboardPayload = {
  __typename?: 'AddDropToMyDashboardPayload';
  userAccount: UserAccount;
};

export enum AlignmentResponseTimeUnit {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes'
}

export type AlignmentRule = Node & {
  __typename?: 'AlignmentRule';
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  leadTerm: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  responseTime: Maybe<Scalars['Int']['output']>;
  responseTimeUnit: Maybe<AlignmentResponseTimeUnit>;
  type: BuyerEngagementType;
};

export type BarChart = Chart & {
  __typename?: 'BarChart';
  datasets: Array<ChartDataset>;
  labels: Array<Scalars['String']['output']>;
  xAxis: Maybe<ChartXAxis>;
  yAxis: Maybe<ChartYAxis>;
};

export type Buyer = Node & {
  __typename?: 'Buyer';
  demandUnit: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  needs: Maybe<Scalars['String']['output']>;
  personas: Array<BuyerPersona>;
  solutions: Maybe<Scalars['String']['output']>;
  suborganizationEdges: Array<BuyerSuborganizationEdge>;
};

export enum BuyerEngagementType {
  Marketing = 'marketing',
  Sales = 'sales'
}

export type BuyerPersona = Node & {
  __typename?: 'BuyerPersona';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  suborganization: Maybe<Organization>;
};

export type BuyerSuborganizationEdge = Node & {
  __typename?: 'BuyerSuborganizationEdge';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  needs: Maybe<Scalars['String']['output']>;
  node: Organization;
  solutions: Maybe<Scalars['String']['output']>;
};

export type Channel = Node & {
  __typename?: 'Channel';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type Chart = {
  datasets: Array<ChartDataset>;
  labels: Array<Scalars['String']['output']>;
  xAxis: Maybe<ChartXAxis>;
  yAxis: Maybe<ChartYAxis>;
};

export type ChartAxis = {
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<ChartAxisType>;
};

export enum ChartAxisType {
  Category = 'category',
  Time = 'time'
}

export type ChartDataset = {
  __typename?: 'ChartDataset';
  color: Maybe<Array<Scalars['HexColorCode']['output']>>;
  data: Array<Scalars['Float']['output']>;
  label: Maybe<Scalars['String']['output']>;
};

export type ChartXAxis = ChartAxis & {
  __typename?: 'ChartXAxis';
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<ChartAxisType>;
};

export type ChartYAxis = ChartAxis & {
  __typename?: 'ChartYAxis';
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<ChartAxisType>;
};

export type CloneSplashInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CloneSplashPayload = {
  __typename?: 'CloneSplashPayload';
  splash: Splash;
};

export type ContentItem = {
  additionalCallsToAction: Array<Scalars['String']['output']>;
  /** @deprecated Use associated webletHost.baseUrl field instead. */
  baseUrl: Maybe<Scalars['String']['output']>;
  /** @deprecated Use weblets field instead. */
  deployments: Array<Weblet>;
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  stage: Stage;
  webletHost: WebletHost;
  weblets: Array<Weblet>;
};


export type ContentItemDeploymentsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type ContentItemWebletsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContentSpec = Node & {
  __typename?: 'ContentSpec';
  assignedAt: Scalars['DateTime']['output'];
  comments: Maybe<Scalars['String']['output']>;
  creator: Scalars['String']['output'];
  dueAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  organization: Organization;
  splash: Splash;
  stageEdges: Array<ContentSpecStageEdge>;
  /** @deprecated Use organization field instead. */
  suborganization: Organization;
};

export type ContentSpecStageEdge = {
  __typename?: 'ContentSpecStageEdge';
  contentItems: Array<ContentItem>;
  node: Stage;
};

export type CreateBuyerInput = {
  demandUnit?: InputMaybe<Scalars['String']['input']>;
  splashId: Scalars['ID']['input'];
};

export type CreateBuyerPayload = {
  __typename?: 'CreateBuyerPayload';
  buyer: Buyer;
  splash: Splash;
};

export type CreateBuyerPersonaInput = {
  buyerId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateBuyerPersonaPayload = {
  __typename?: 'CreateBuyerPersonaPayload';
  buyer: Buyer;
  persona: BuyerPersona;
};

export type CreateBuyerSuborganizationEdgeInput = {
  buyerId: Scalars['ID']['input'];
  needs?: InputMaybe<Scalars['String']['input']>;
  solutions?: InputMaybe<Scalars['String']['input']>;
  suborganizationId: Scalars['ID']['input'];
};

export type CreateBuyerSuborganizationEdgePayload = {
  __typename?: 'CreateBuyerSuborganizationEdgePayload';
  buyer: Buyer;
  buyerSuborganizationEdge: BuyerSuborganizationEdge;
};

export type CreateChannelInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  splashId: Scalars['ID']['input'];
};

export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  channel: Channel;
  splash: Splash;
};

export type CreateContentSpecInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  contentItemIds: Array<Scalars['ID']['input']>;
  creator: Scalars['String']['input'];
  dueAt: Scalars['DateTime']['input'];
  suborganizationId: Scalars['ID']['input'];
};

export type CreateContentSpecPayload = {
  __typename?: 'CreateContentSpecPayload';
  contentSpec: ContentSpec;
};

export type CreateJourneyPersonalizationRequiredFormItemInput = {
  journeyPersonalizationId: Scalars['ID']['input'];
};

export type CreateJourneyPersonalizationRequiredFormItemPayload = {
  __typename?: 'CreateJourneyPersonalizationRequiredFormItemPayload';
  journeyPersonalization: JourneyPersonalization;
  requiredFormItem: JourneyPersonalizationRequiredFormItem;
};

export type CreateNurtureContentAssetInput = {
  additionalCallsToAction?: InputMaybe<Array<Scalars['String']['input']>>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stageId: Scalars['ID']['input'];
};

export type CreateNurtureContentAssetPayload = {
  __typename?: 'CreateNurtureContentAssetPayload';
  nurtureContentAsset: NurtureContentAsset;
  stage: Stage;
};

export type CreateObjectiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  splashId: Scalars['ID']['input'];
};

export type CreateObjectiveMeasurementInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  objectiveId: Scalars['ID']['input'];
};

export type CreateObjectiveMeasurementPayload = {
  __typename?: 'CreateObjectiveMeasurementPayload';
  measurement: ObjectiveMeasurement;
  objective: Objective;
};

export type CreateObjectivePayload = {
  __typename?: 'CreateObjectivePayload';
  objective: Objective;
  splash: Splash;
};

export type CreateQualificationActionableInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  followUpResponsibilities?: InputMaybe<Array<BuyerEngagementType>>;
  stageId: Scalars['ID']['input'];
};

export type CreateQualificationActionablePayload = {
  __typename?: 'CreateQualificationActionablePayload';
  qualificationActionable: QualificationActionable;
  stage: Stage;
};

export type CreateSmartContentExperienceInput = {
  additionalCallsToAction?: InputMaybe<Array<Scalars['String']['input']>>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  qualificationContribution?: InputMaybe<Scalars['String']['input']>;
  stageId: Scalars['ID']['input'];
};

export type CreateSmartContentExperiencePayload = {
  __typename?: 'CreateSmartContentExperiencePayload';
  smartContentExperience: SmartContentExperience;
  stage: Stage;
};

export type CreateSplashContributorEdgeInput = {
  contribution: Scalars['Float']['input'];
  splashId: Scalars['ID']['input'];
  suborganizationId: Scalars['ID']['input'];
};

export type CreateSplashContributorEdgePayload = {
  __typename?: 'CreateSplashContributorEdgePayload';
  splash: Splash;
  splashContributorEdge: SplashContributorEdge;
};

export type CreateSplashInput = {
  averageSalesPrice?: InputMaybe<Scalars['Float']['input']>;
  includeExampleData?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  revenueTarget?: InputMaybe<Scalars['Float']['input']>;
  splashBudget?: InputMaybe<Scalars['Float']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSplashPayload = {
  __typename?: 'CreateSplashPayload';
  /** @deprecated Use Splash.organization field instead. */
  organization: Organization;
  splash: Splash;
};

export type CreateStageEntryRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  stageId: Scalars['String']['input'];
};

export type CreateStageEntryRulePayload = {
  __typename?: 'CreateStageEntryRulePayload';
  stage: Stage;
  stageEntryRule: StageEntryRule;
};

export type CreateThirdPartyIntegrationInput = {
  contentItemId: Scalars['ID']['input'];
  platform?: InputMaybe<ThirdPartyPlatform>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateThirdPartyIntegrationPayload = {
  __typename?: 'CreateThirdPartyIntegrationPayload';
  contentItem: ContentItem;
  thirdPartyIntegration: ThirdPartyIntegration;
};

export type DeactivateSplashInput = {
  id: Scalars['ID']['input'];
};

export type DeactivateSplashPayload = {
  __typename?: 'DeactivateSplashPayload';
  splash: Splash;
};

export type DeleteBuyerInput = {
  id: Scalars['ID']['input'];
};

export type DeleteBuyerPayload = {
  __typename?: 'DeleteBuyerPayload';
  buyerId: Scalars['ID']['output'];
  splash: Splash;
};

export type DeleteBuyerPersonaInput = {
  id: Scalars['ID']['input'];
};

export type DeleteBuyerPersonaPayload = {
  __typename?: 'DeleteBuyerPersonaPayload';
  buyer: Buyer;
  personaId: Scalars['ID']['output'];
};

export type DeleteBuyerSuborganizationEdgeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteBuyerSuborganizationEdgePayload = {
  __typename?: 'DeleteBuyerSuborganizationEdgePayload';
  buyer: Buyer;
  buyerSuborganizationEdgeId: Scalars['ID']['output'];
};

export type DeleteChannelInput = {
  id: Scalars['ID']['input'];
};

export type DeleteChannelPayload = {
  __typename?: 'DeleteChannelPayload';
  channelId: Scalars['ID']['output'];
  splash: Splash;
};

export type DeleteJourneyPersonalizationRequiredFormItemInput = {
  id: Scalars['ID']['input'];
};

export type DeleteJourneyPersonalizationRequiredFormItemPayload = {
  __typename?: 'DeleteJourneyPersonalizationRequiredFormItemPayload';
  journeyPersonalization: JourneyPersonalization;
  requiredFormItemId: Scalars['ID']['output'];
};

export type DeleteNurtureContentAssetInput = {
  id: Scalars['ID']['input'];
};

export type DeleteNurtureContentAssetPayload = {
  __typename?: 'DeleteNurtureContentAssetPayload';
  nurtureContentAssetId: Scalars['ID']['output'];
  stage: Stage;
};

export type DeleteObjectiveInput = {
  id: Scalars['ID']['input'];
};

export type DeleteObjectiveMeasurementInput = {
  id: Scalars['ID']['input'];
};

export type DeleteObjectiveMeasurementPayload = {
  __typename?: 'DeleteObjectiveMeasurementPayload';
  measurementId: Scalars['ID']['output'];
  objective: Objective;
};

export type DeleteObjectivePayload = {
  __typename?: 'DeleteObjectivePayload';
  objectiveId: Scalars['ID']['output'];
  splash: Splash;
};

export type DeleteQualificationActionableInput = {
  id: Scalars['ID']['input'];
};

export type DeleteQualificationActionablePayload = {
  __typename?: 'DeleteQualificationActionablePayload';
  qualificationActionableId: Scalars['ID']['output'];
  stage: Stage;
};

export type DeleteSmartContentExperienceInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSmartContentExperiencePayload = {
  __typename?: 'DeleteSmartContentExperiencePayload';
  smartContentExperienceId: Scalars['ID']['output'];
  stage: Stage;
};

export type DeleteSplashContributorEdgeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSplashContributorEdgePayload = {
  __typename?: 'DeleteSplashContributorEdgePayload';
  splash: Splash;
  splashContributorEdgeId: Scalars['ID']['output'];
};

export type DeleteSplashInput = {
  id: Scalars['ID']['input'];
};

export type DeleteSplashPayload = {
  __typename?: 'DeleteSplashPayload';
  splash: Splash;
  /** @deprecated Use splash field instead. */
  splashId: Scalars['ID']['output'];
};

export type DeleteStageEntryRuleInput = {
  id: Scalars['String']['input'];
};

export type DeleteStageEntryRulePayload = {
  __typename?: 'DeleteStageEntryRulePayload';
  stage: Stage;
  stageEntryRuleId: Scalars['String']['output'];
};

export type DeleteThirdPartyIntegrationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteThirdPartyIntegrationPayload = {
  __typename?: 'DeleteThirdPartyIntegrationPayload';
  /** @deprecated Use ThirdPartyIntegration.contentItem instead. */
  contentItem: ContentItem;
  thirdPartyIntegrationId: Scalars['ID']['output'];
};

export type Drop = Node & {
  __typename?: 'Drop';
  chart: Chart;
  dataSourceNames: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  droplet: Droplet;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  size: DropSize;
};


export type DropChartArgs = {
  endDate: Scalars['DateTime']['input'];
  salesChannelMode?: InputMaybe<SalesChannelMode>;
  splashId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DropSize {
  Lg = 'lg',
  Md = 'md',
  Sm = 'sm'
}

export type Droplet = {
  __typename?: 'Droplet';
  chart: Chart;
};


export type DropletChartArgs = {
  endDate: Scalars['DateTime']['input'];
  salesChannelMode?: InputMaybe<SalesChannelMode>;
  splashId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type Flow = Node & {
  __typename?: 'Flow';
  drops: Array<Drop>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum FormBehavior {
  AutoHandleFieldsWithKnownValues = 'autoHandleFieldsWithKnownValues',
  IncludeGdprConsent = 'includeGdprConsent',
  IncludePrivacyPolicyNotice = 'includePrivacyPolicyNotice',
  UseCaptcha = 'useCaptcha'
}

export type JourneyPersonalization = Node & {
  __typename?: 'JourneyPersonalization';
  disclaimer: Maybe<Scalars['String']['output']>;
  formBehaviors: Array<FormBehavior>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  requiredFormItems: Array<JourneyPersonalizationRequiredFormItem>;
};

export type JourneyPersonalizationRequiredFormItem = Node & {
  __typename?: 'JourneyPersonalizationRequiredFormItem';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  includeInContent: Scalars['Boolean']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type LaunchSplashInput = {
  id: Scalars['ID']['input'];
};

export type LaunchSplashPayload = {
  __typename?: 'LaunchSplashPayload';
  splash: Splash;
};

export type LineChart = Chart & {
  __typename?: 'LineChart';
  datasets: Array<ChartDataset>;
  labels: Array<Scalars['String']['output']>;
  xAxis: Maybe<ChartXAxis>;
  yAxis: Maybe<ChartYAxis>;
};

export type LinkContentItemInput = {
  fromContentItemId: Scalars['ID']['input'];
  toContentItemId: Scalars['ID']['input'];
};

export type LinkContentItemPayload = {
  __typename?: 'LinkContentItemPayload';
  fromContentItem: ContentItem;
  toContentItem: ContentItem;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSplash: ActivateSplashPayload;
  addDropToMyDashboard: AddDropToMyDashboardPayload;
  cloneSplash: CloneSplashPayload;
  createBuyer: CreateBuyerPayload;
  createBuyerPersona: CreateBuyerPersonaPayload;
  createBuyerSuborganizationEdge: CreateBuyerSuborganizationEdgePayload;
  createChannel: CreateChannelPayload;
  createContentSpec: CreateContentSpecPayload;
  createJourneyPersonalizationRequiredFormItem: CreateJourneyPersonalizationRequiredFormItemPayload;
  createNurtureContentAsset: CreateNurtureContentAssetPayload;
  createObjective: CreateObjectivePayload;
  createObjectiveMeasurement: CreateObjectiveMeasurementPayload;
  createQualificationActionable: CreateQualificationActionablePayload;
  createSmartContentExperience: CreateSmartContentExperiencePayload;
  createSplash: CreateSplashPayload;
  createSplashContributorEdge: CreateSplashContributorEdgePayload;
  createStageEntryRule: CreateStageEntryRulePayload;
  createThirdPartyIntegration: CreateThirdPartyIntegrationPayload;
  deactivateSplash: DeactivateSplashPayload;
  deleteBuyer: DeleteBuyerPayload;
  deleteBuyerPersona: DeleteBuyerPersonaPayload;
  deleteBuyerSuborganizationEdge: DeleteBuyerSuborganizationEdgePayload;
  deleteChannel: DeleteChannelPayload;
  deleteJourneyPersonalizationRequiredFormItem: DeleteJourneyPersonalizationRequiredFormItemPayload;
  deleteNurtureContentAsset: DeleteNurtureContentAssetPayload;
  deleteObjective: DeleteObjectivePayload;
  deleteObjectiveMeasurement: DeleteObjectiveMeasurementPayload;
  deleteQualificationActionable: DeleteQualificationActionablePayload;
  deleteSmartContentExperience: DeleteSmartContentExperiencePayload;
  deleteSplash: DeleteSplashPayload;
  deleteSplashContributorEdge: DeleteSplashContributorEdgePayload;
  deleteStageEntryRule: DeleteStageEntryRulePayload;
  deleteThirdPartyIntegration: DeleteThirdPartyIntegrationPayload;
  launchSplash: LaunchSplashPayload;
  linkContentItem: LinkContentItemPayload;
  putWeblet: UpdateContentItemDeploymentPayload;
  removeDropFromMyDashboard: RemoveDropFromMyDashboardPayload;
  suspendSplash: SuspendSplashPayload;
  syncSmartContentExperienceBuyers: SyncSmartContentExperienceBuyersPayload;
  unlinkContentItem: UnlinkContentItemPayload;
  unsyncSmartContentExperienceBuyers: UnsyncSmartContentExperienceBuyersPayload;
  updateAlignmentRule: UpdateAlignmentRulePayload;
  updateBuyer: UpdateBuyerPayload;
  updateBuyerPersona: UpdateBuyerPersonaPayload;
  updateBuyerSuborganizationEdge: UpdateBuyerSuborganizationEdgePayload;
  updateChannel: UpdateChannelPayload;
  /** @deprecated Use putWeblet instead. */
  updateContentItemDeployment: UpdateContentItemDeploymentPayload;
  updateJourneyPersonalization: UpdateJourneyPersonalizationPayload;
  updateJourneyPersonalizationRequiredFormItem: UpdateJourneyPersonalizationRequiredFormItemPayload;
  updateNurtureContentAsset: UpdateNurtureContentAssetPayload;
  updateObjective: UpdateObjectivePayload;
  updateObjectiveMeasurement: UpdateObjectiveMeasurementPayload;
  updatePlan: UpdatePlanPayload;
  updateQualificationActionable: UpdateQualificationActionablePayload;
  updateSmartContentExperience: UpdateSmartContentExperiencePayload;
  updateSmartContentExperienceBuyerEdge: UpdateSmartContentExperienceBuyerEdgePayload;
  updateSplashContributorEdge: UpdateSplashContributorEdgePayload;
  updateStage: UpdateStagePayload;
  updateStageEntryRule: UpdateStageEntryRulePayload;
  updateThirdPartyIntegration: UpdateThirdPartyIntegrationPayload;
};


export type MutationActivateSplashArgs = {
  input: ActivateSplashInput;
};


export type MutationAddDropToMyDashboardArgs = {
  dropId: Scalars['ID']['input'];
  splashId: Scalars['ID']['input'];
};


export type MutationCloneSplashArgs = {
  input: CloneSplashInput;
};


export type MutationCreateBuyerArgs = {
  input: CreateBuyerInput;
};


export type MutationCreateBuyerPersonaArgs = {
  input: CreateBuyerPersonaInput;
};


export type MutationCreateBuyerSuborganizationEdgeArgs = {
  input: CreateBuyerSuborganizationEdgeInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateContentSpecArgs = {
  input: CreateContentSpecInput;
};


export type MutationCreateJourneyPersonalizationRequiredFormItemArgs = {
  input: CreateJourneyPersonalizationRequiredFormItemInput;
};


export type MutationCreateNurtureContentAssetArgs = {
  input: CreateNurtureContentAssetInput;
};


export type MutationCreateObjectiveArgs = {
  input: CreateObjectiveInput;
};


export type MutationCreateObjectiveMeasurementArgs = {
  input: CreateObjectiveMeasurementInput;
};


export type MutationCreateQualificationActionableArgs = {
  input: CreateQualificationActionableInput;
};


export type MutationCreateSmartContentExperienceArgs = {
  input: CreateSmartContentExperienceInput;
};


export type MutationCreateSplashArgs = {
  input: CreateSplashInput;
};


export type MutationCreateSplashContributorEdgeArgs = {
  input: CreateSplashContributorEdgeInput;
};


export type MutationCreateStageEntryRuleArgs = {
  input: CreateStageEntryRuleInput;
};


export type MutationCreateThirdPartyIntegrationArgs = {
  input: CreateThirdPartyIntegrationInput;
};


export type MutationDeactivateSplashArgs = {
  input: DeactivateSplashInput;
};


export type MutationDeleteBuyerArgs = {
  input: DeleteBuyerInput;
};


export type MutationDeleteBuyerPersonaArgs = {
  input: DeleteBuyerPersonaInput;
};


export type MutationDeleteBuyerSuborganizationEdgeArgs = {
  input: DeleteBuyerSuborganizationEdgeInput;
};


export type MutationDeleteChannelArgs = {
  input: DeleteChannelInput;
};


export type MutationDeleteJourneyPersonalizationRequiredFormItemArgs = {
  input: DeleteJourneyPersonalizationRequiredFormItemInput;
};


export type MutationDeleteNurtureContentAssetArgs = {
  input: DeleteNurtureContentAssetInput;
};


export type MutationDeleteObjectiveArgs = {
  input: DeleteObjectiveInput;
};


export type MutationDeleteObjectiveMeasurementArgs = {
  input: DeleteObjectiveMeasurementInput;
};


export type MutationDeleteQualificationActionableArgs = {
  input: DeleteQualificationActionableInput;
};


export type MutationDeleteSmartContentExperienceArgs = {
  input: DeleteSmartContentExperienceInput;
};


export type MutationDeleteSplashArgs = {
  input: DeleteSplashInput;
};


export type MutationDeleteSplashContributorEdgeArgs = {
  input: DeleteSplashContributorEdgeInput;
};


export type MutationDeleteStageEntryRuleArgs = {
  input: DeleteStageEntryRuleInput;
};


export type MutationDeleteThirdPartyIntegrationArgs = {
  input: DeleteThirdPartyIntegrationInput;
};


export type MutationLaunchSplashArgs = {
  input: LaunchSplashInput;
};


export type MutationLinkContentItemArgs = {
  input: LinkContentItemInput;
};


export type MutationPutWebletArgs = {
  input: UpdateContentItemDeploymentInput;
};


export type MutationRemoveDropFromMyDashboardArgs = {
  dropId: Scalars['ID']['input'];
  splashId: Scalars['ID']['input'];
};


export type MutationSuspendSplashArgs = {
  input: SuspendSplashInput;
};


export type MutationSyncSmartContentExperienceBuyersArgs = {
  input: SyncSmartContentExperienceBuyersInput;
};


export type MutationUnlinkContentItemArgs = {
  input: UnlinkContentItemInput;
};


export type MutationUnsyncSmartContentExperienceBuyersArgs = {
  input: UnsyncSmartContentExperienceBuyersInput;
};


export type MutationUpdateAlignmentRuleArgs = {
  input: UpdateAlignmentRuleInput;
};


export type MutationUpdateBuyerArgs = {
  input: UpdateBuyerInput;
};


export type MutationUpdateBuyerPersonaArgs = {
  input: UpdateBuyerPersonaInput;
};


export type MutationUpdateBuyerSuborganizationEdgeArgs = {
  input: UpdateBuyerSuborganizationEdgeInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateContentItemDeploymentArgs = {
  input: UpdateContentItemDeploymentInput;
};


export type MutationUpdateJourneyPersonalizationArgs = {
  input: UpdateJourneyPersonalizationInput;
};


export type MutationUpdateJourneyPersonalizationRequiredFormItemArgs = {
  input: UpdateJourneyPersonalizationRequiredFormItemInput;
};


export type MutationUpdateNurtureContentAssetArgs = {
  input: UpdateNurtureContentAssetInput;
};


export type MutationUpdateObjectiveArgs = {
  input: UpdateObjectiveInput;
};


export type MutationUpdateObjectiveMeasurementArgs = {
  input: UpdateObjectiveMeasurementInput;
};


export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


export type MutationUpdateQualificationActionableArgs = {
  input: UpdateQualificationActionableInput;
};


export type MutationUpdateSmartContentExperienceArgs = {
  input: UpdateSmartContentExperienceInput;
};


export type MutationUpdateSmartContentExperienceBuyerEdgeArgs = {
  input: UpdateSmartContentExperienceBuyerEdgeInput;
};


export type MutationUpdateSplashContributorEdgeArgs = {
  input: UpdateSplashContributorEdgeInput;
};


export type MutationUpdateStageArgs = {
  input: UpdateStageInput;
};


export type MutationUpdateStageEntryRuleArgs = {
  input: UpdateStageEntryRuleInput;
};


export type MutationUpdateThirdPartyIntegrationArgs = {
  input: UpdateThirdPartyIntegrationInput;
};

/** An object with an ID. */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type NurtureContentAsset = ContentItem & {
  __typename?: 'NurtureContentAsset';
  additionalCallsToAction: Array<Scalars['String']['output']>;
  automatedFlowProgressionLinks: Array<SmartContentExperience>;
  /** @deprecated Use associated webletHost.baseUrl field instead. */
  baseUrl: Maybe<Scalars['String']['output']>;
  /** @deprecated Use weblets field instead. */
  deployments: Array<Weblet>;
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  stage: Stage;
  thirdPartyIntegrations: Array<ThirdPartyIntegration>;
  webletHost: WebletHost;
  weblets: Array<Weblet>;
};


export type NurtureContentAssetDeploymentsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type NurtureContentAssetWebletsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type Objective = Node & {
  __typename?: 'Objective';
  description: Maybe<Scalars['String']['output']>;
  drops: Array<Drop>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  measurements: Array<ObjectiveMeasurement>;
  name: Maybe<Scalars['String']['output']>;
};

export type ObjectiveMeasurement = Node & {
  __typename?: 'ObjectiveMeasurement';
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type Organization = Node & {
  __typename?: 'Organization';
  children: Array<Organization>;
  descendants: Array<Organization>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isLeaf: Scalars['Boolean']['output'];
  isRoot: Scalars['Boolean']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent: Maybe<Organization>;
  splashes: Array<Maybe<Splash>>;
  /** @deprecated Use descendants field instead. */
  suborganizations: Array<Organization>;
  webletHosts: Array<WebletHost>;
};

export type PieChart = Chart & {
  __typename?: 'PieChart';
  datasets: Array<ChartDataset>;
  labels: Array<Scalars['String']['output']>;
  xAxis: Maybe<ChartXAxis>;
  yAxis: Maybe<ChartYAxis>;
};

export type Plan = Node & {
  __typename?: 'Plan';
  averageSalesPrice: Maybe<Scalars['Int']['output']>;
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  impressionsPerEntryRatio: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  revenueTarget: Maybe<Scalars['Int']['output']>;
  splashBudget: Maybe<Scalars['Int']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  timeOfFlightDuration: Maybe<Scalars['Int']['output']>;
};

export type QualificationActionable = Node & {
  __typename?: 'QualificationActionable';
  description: Maybe<Scalars['String']['output']>;
  followUpResponsibilities: Array<BuyerEngagementType>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  operationsPlay: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  contentSpec: ContentSpec;
  currentUser: UserAccount;
  drop: Drop;
  flow: Flow;
  objective: Objective;
  organization: Organization;
  splash: Splash;
  splashStage: Stage;
  splashTemplates: SplashTemplates;
  splashes: Array<Splash>;
};


export type QueryContentSpecArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDropArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFlowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryObjectiveArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySplashArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySplashStageArgs = {
  id: Scalars['ID']['input'];
};

export enum RealTimePersonalization {
  Country = 'country',
  Language = 'language',
  ReferralSource = 'referralSource'
}

export type RemoveDropFromMyDashboardPayload = {
  __typename?: 'RemoveDropFromMyDashboardPayload';
  userAccount: UserAccount;
};

export enum SalesChannelMode {
  Direct = 'direct',
  Partner = 'partner'
}

export type SmartContentExperience = ContentItem & {
  __typename?: 'SmartContentExperience';
  additionalCallsToAction: Array<Scalars['String']['output']>;
  automatedFlowProgressionLinks: Array<SmartContentExperience>;
  automatedNurtureLinks: Array<NurtureContentAsset>;
  /** @deprecated Use associated webletHost.baseUrl field instead. */
  baseUrl: Maybe<Scalars['String']['output']>;
  buyerEdges: Array<SmartContentExperienceBuyerEdge>;
  buyersAreSynced: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use weblets field instead. */
  deployments: Array<Weblet>;
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  qualificationContribution: Maybe<Scalars['String']['output']>;
  stage: Stage;
  stageMessaging: Maybe<Scalars['String']['output']>;
  thirdPartyIntegrations: Array<ThirdPartyIntegration>;
  webletHost: WebletHost;
  weblets: Array<Weblet>;
};


export type SmartContentExperienceDeploymentsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type SmartContentExperienceWebletsArgs = {
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type SmartContentExperienceBuyerEdge = Node & {
  __typename?: 'SmartContentExperienceBuyerEdge';
  additionalNotes: Maybe<Array<Scalars['String']['output']>>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  interaction: Maybe<Scalars['String']['output']>;
  interactionBasedRtp: Maybe<Scalars['String']['output']>;
  messaging: Maybe<Scalars['String']['output']>;
  node: Buyer;
};

export type Splash = Node & {
  __typename?: 'Splash';
  alignmentRules: Array<AlignmentRule>;
  buyers: Array<Buyer>;
  channels: Array<Channel>;
  contributorEdges: Array<SplashContributorEdge>;
  flows: Array<Flow>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  journeyPersonalization: JourneyPersonalization;
  objectives: Array<Objective>;
  organization: Organization;
  plan: Plan;
  stages: Array<Stage>;
  status: SplashStatus;
};

export type SplashContributorEdge = Node & {
  __typename?: 'SplashContributorEdge';
  contribution: Scalars['Float']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  node: Organization;
};

export enum SplashStatus {
  Active = 'active',
  Inactive = 'inactive',
  Launched = 'launched'
}

export type SplashTemplate = Node & {
  __typename?: 'SplashTemplate';
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  stages: Array<StageTemplate>;
};

export type SplashTemplates = {
  __typename?: 'SplashTemplates';
  defaultTemplate: SplashTemplate;
  templates: Array<SplashTemplate>;
};

export type Stage = Node & {
  __typename?: 'Stage';
  additionalProfilingItems: Array<Scalars['String']['output']>;
  additionalProfilingVia: StageAdditionalProfilingSource;
  additionalRealTimePersonalizations: Array<Scalars['String']['output']>;
  budgetAllocation: Maybe<Scalars['Int']['output']>;
  entryRules: Array<StageEntryRule>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nextStage: Maybe<Stage>;
  nurtureContentAssets: Array<NurtureContentAsset>;
  phase: StagePhase;
  profilingTiming: StageProfilingTiming;
  progressionRate: Scalars['Float']['output'];
  progressionTiming: Scalars['Int']['output'];
  qualificationActionables: Array<QualificationActionable>;
  qualificationThreshold: Maybe<Scalars['String']['output']>;
  realTimePersonalizations: Array<RealTimePersonalization>;
  smartContentExperiences: Array<SmartContentExperience>;
};

export enum StageAdditionalProfilingSource {
  Content = 'content',
  Form = 'form'
}

export type StageEntryRule = Node & {
  __typename?: 'StageEntryRule';
  description: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
};

export enum StagePhase {
  Five = 'five',
  Four = 'four',
  One = 'one',
  Three = 'three',
  Two = 'two'
}

export enum StageProfilingTiming {
  Beginning = 'beginning',
  End = 'end',
  Middle = 'middle'
}

export type StageTemplate = {
  __typename?: 'StageTemplate';
  name: Scalars['String']['output'];
  phase: StagePhase;
};

export type SuspendSplashInput = {
  id: Scalars['ID']['input'];
};

export type SuspendSplashPayload = {
  __typename?: 'SuspendSplashPayload';
  splash: Splash;
};

export type SyncSmartContentExperienceBuyersInput = {
  smartContentExperienceId: Scalars['ID']['input'];
  syncToBuyerId: Scalars['ID']['input'];
};

export type SyncSmartContentExperienceBuyersPayload = {
  __typename?: 'SyncSmartContentExperienceBuyersPayload';
  smartContentExperience: SmartContentExperience;
};

export type ThirdPartyIntegration = Node & {
  __typename?: 'ThirdPartyIntegration';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  platform: Maybe<ThirdPartyPlatform>;
  value: Maybe<Scalars['String']['output']>;
};

export enum ThirdPartyPlatform {
  Adobe = 'adobe',
  Eloqua = 'eloqua',
  Google = 'google',
  HubSpot = 'hubSpot',
  Marketo = 'marketo',
  Pardot = 'pardot',
  Salesforce = 'salesforce'
}

export type UnlinkContentItemInput = {
  fromContentItemId: Scalars['ID']['input'];
  toContentItemId: Scalars['ID']['input'];
};

export type UnlinkContentItemPayload = {
  __typename?: 'UnlinkContentItemPayload';
  fromContentItem: ContentItem;
  toContentItem: ContentItem;
};

export type UnsyncSmartContentExperienceBuyersInput = {
  smartContentExperienceId: Scalars['ID']['input'];
};

export type UnsyncSmartContentExperienceBuyersPayload = {
  __typename?: 'UnsyncSmartContentExperienceBuyersPayload';
  smartContentExperience: SmartContentExperience;
};

export type UpdateAlignmentRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  leadTerm?: InputMaybe<Scalars['String']['input']>;
  responseTime?: InputMaybe<Scalars['Int']['input']>;
  responseTimeUnit?: InputMaybe<AlignmentResponseTimeUnit>;
};

export type UpdateAlignmentRulePayload = {
  __typename?: 'UpdateAlignmentRulePayload';
  alignmentRule: AlignmentRule;
};

export type UpdateBuyerInput = {
  demandUnit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  needs?: InputMaybe<Scalars['String']['input']>;
  solutions?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuyerPayload = {
  __typename?: 'UpdateBuyerPayload';
  buyer: Buyer;
  splash: Splash;
};

export type UpdateBuyerPersonaInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuyerPersonaPayload = {
  __typename?: 'UpdateBuyerPersonaPayload';
  persona: BuyerPersona;
};

export type UpdateBuyerSuborganizationEdgeInput = {
  id: Scalars['ID']['input'];
  messaging?: InputMaybe<Scalars['String']['input']>;
  needs?: InputMaybe<Scalars['String']['input']>;
  solutions?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuyerSuborganizationEdgePayload = {
  __typename?: 'UpdateBuyerSuborganizationEdgePayload';
  buyer: Buyer;
  buyerSuborganizationEdge: BuyerSuborganizationEdge;
};

export type UpdateChannelInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChannelPayload = {
  __typename?: 'UpdateChannelPayload';
  channel: Channel;
};

export type UpdateContentItemDeploymentInput = {
  id: Scalars['ID']['input'];
  messaging?: InputMaybe<Scalars['String']['input']>;
  relativeUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContentItemDeploymentPayload = {
  __typename?: 'UpdateContentItemDeploymentPayload';
  contentItemDeployment: Weblet;
};

export type UpdateJourneyPersonalizationInput = {
  disclaimer?: InputMaybe<Scalars['String']['input']>;
  formBehaviors?: InputMaybe<Array<FormBehavior>>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJourneyPersonalizationPayload = {
  __typename?: 'UpdateJourneyPersonalizationPayload';
  journeyPersonalization: JourneyPersonalization;
};

export type UpdateJourneyPersonalizationRequiredFormItemInput = {
  id: Scalars['ID']['input'];
  includeInContent?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJourneyPersonalizationRequiredFormItemPayload = {
  __typename?: 'UpdateJourneyPersonalizationRequiredFormItemPayload';
  journeyPersonalization: JourneyPersonalization;
  requiredFormItem: JourneyPersonalizationRequiredFormItem;
};

export type UpdateNurtureContentAssetInput = {
  additionalCallsToAction?: InputMaybe<Array<Scalars['String']['input']>>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  webletHostId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateNurtureContentAssetPayload = {
  __typename?: 'UpdateNurtureContentAssetPayload';
  nurtureContentAsset: NurtureContentAsset;
};

export type UpdateObjectiveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateObjectiveMeasurementInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateObjectiveMeasurementPayload = {
  __typename?: 'UpdateObjectiveMeasurementPayload';
  measurement: ObjectiveMeasurement;
  objective: Objective;
};

export type UpdateObjectivePayload = {
  __typename?: 'UpdateObjectivePayload';
  objective: Objective;
  splash: Splash;
};

export type UpdatePlanInput = {
  averageSalesPrice?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  impressionsPerEntryRatio?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  revenueTarget?: InputMaybe<Scalars['Float']['input']>;
  splashBudget?: InputMaybe<Scalars['Float']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  timeOfFlightDuration?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: Plan;
};

export type UpdateQualificationActionableInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  followUpResponsibilities?: InputMaybe<Array<BuyerEngagementType>>;
  id: Scalars['ID']['input'];
  operationsPlay?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQualificationActionablePayload = {
  __typename?: 'UpdateQualificationActionablePayload';
  qualificationActionable: QualificationActionable;
  stage: Stage;
};

export type UpdateSmartContentExperienceBuyerEdgeInput = {
  additionalNotes?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  interaction?: InputMaybe<Scalars['String']['input']>;
  interactionBasedRtp?: InputMaybe<Scalars['String']['input']>;
  messaging?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSmartContentExperienceBuyerEdgePayload = {
  __typename?: 'UpdateSmartContentExperienceBuyerEdgePayload';
  edge: SmartContentExperienceBuyerEdge;
};

export type UpdateSmartContentExperienceInput = {
  additionalCallsToAction?: InputMaybe<Array<Scalars['String']['input']>>;
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  qualificationContribution?: InputMaybe<Scalars['String']['input']>;
  stageMessaging?: InputMaybe<Scalars['String']['input']>;
  webletHostId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateSmartContentExperiencePayload = {
  __typename?: 'UpdateSmartContentExperiencePayload';
  smartContentExperience: SmartContentExperience;
};

export type UpdateSplashContributorEdgeInput = {
  contribution: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateSplashContributorEdgePayload = {
  __typename?: 'UpdateSplashContributorEdgePayload';
  splashContributorEdge: SplashContributorEdge;
};

export type UpdateStageEntryRuleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateStageEntryRulePayload = {
  __typename?: 'UpdateStageEntryRulePayload';
  stage: Stage;
  stageEntryRule: StageEntryRule;
};

export type UpdateStageInput = {
  additionalProfilingItems?: InputMaybe<Array<Scalars['String']['input']>>;
  additionalProfilingVia?: InputMaybe<StageAdditionalProfilingSource>;
  additionalRealTimePersonalizations?: InputMaybe<Array<Scalars['String']['input']>>;
  budgetAllocation?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  profilingTiming?: InputMaybe<StageProfilingTiming>;
  progressionRate?: InputMaybe<Scalars['Float']['input']>;
  progressionTiming?: InputMaybe<Scalars['Float']['input']>;
  qualificationThreshold?: InputMaybe<Scalars['String']['input']>;
  realTimePersonalizations?: InputMaybe<Array<RealTimePersonalization>>;
};

export type UpdateStagePayload = {
  __typename?: 'UpdateStagePayload';
  splash: Splash;
  stage: Stage;
};

export type UpdateThirdPartyIntegrationInput = {
  id: Scalars['ID']['input'];
  platform?: InputMaybe<ThirdPartyPlatform>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateThirdPartyIntegrationPayload = {
  __typename?: 'UpdateThirdPartyIntegrationPayload';
  thirdPartyIntegration: ThirdPartyIntegration;
};

export type UserAccount = Node & {
  __typename?: 'UserAccount';
  abilityRules: Array<AbilityRule>;
  dashboardDrops: Array<Drop>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  organization: Maybe<Organization>;
  subject: Scalars['String']['output'];
};


export type UserAccountDashboardDropsArgs = {
  splashId: Scalars['ID']['input'];
};

export type Weblet = Node & {
  __typename?: 'Weblet';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  messaging: Maybe<Scalars['String']['output']>;
  /** @deprecated Use organization field instead. */
  node: Organization;
  organization: Organization;
  relativeUrl: Maybe<Scalars['String']['output']>;
  rippleScript: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type WebletHost = Node & {
  __typename?: 'WebletHost';
  baseUrl: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export type AddDropToMyDashboardButtonMutationVariables = Exact<{
  splashId: Scalars['ID']['input'];
  dropId: Scalars['ID']['input'];
}>;


export type AddDropToMyDashboardButtonMutation = { __typename?: 'Mutation', addDropToMyDashboard: { __typename?: 'AddDropToMyDashboardPayload', userAccount: { __typename?: 'UserAccount', id: string, dashboardDrops: Array<{ __typename?: 'Drop', id: string }> } } };

export type DropChartQueryVariables = Exact<{
  splashId: Scalars['ID']['input'];
  dropId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  suborganizationId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type DropChartQuery = { __typename?: 'Query', drop: { __typename?: 'Drop', id: string, droplet: { __typename?: 'Droplet', chart: { __typename: 'BarChart', labels: Array<string>, datasets: Array<{ __typename?: 'ChartDataset', label: string | null, color: Array<string> | null, data: Array<number> }>, xAxis: { __typename?: 'ChartXAxis', type: ChartAxisType | null, name: string | null } | null, yAxis: { __typename?: 'ChartYAxis', type: ChartAxisType | null, name: string | null } | null } | { __typename: 'LineChart', labels: Array<string>, datasets: Array<{ __typename?: 'ChartDataset', label: string | null, color: Array<string> | null, data: Array<number> }>, xAxis: { __typename?: 'ChartXAxis', type: ChartAxisType | null, name: string | null } | null, yAxis: { __typename?: 'ChartYAxis', type: ChartAxisType | null, name: string | null } | null } | { __typename: 'PieChart', labels: Array<string>, datasets: Array<{ __typename?: 'ChartDataset', label: string | null, color: Array<string> | null, data: Array<number> }>, xAxis: { __typename?: 'ChartXAxis', type: ChartAxisType | null, name: string | null } | null, yAxis: { __typename?: 'ChartYAxis', type: ChartAxisType | null, name: string | null } | null } } } };

export type RemoveDropFromMyDashboardButtonMutationVariables = Exact<{
  splashId: Scalars['ID']['input'];
  dropId: Scalars['ID']['input'];
}>;


export type RemoveDropFromMyDashboardButtonMutation = { __typename?: 'Mutation', removeDropFromMyDashboard: { __typename?: 'RemoveDropFromMyDashboardPayload', userAccount: { __typename?: 'UserAccount', id: string, dashboardDrops: Array<{ __typename?: 'Drop', id: string }> } } };


export const AddDropToMyDashboardButtonDocument = gql`
    mutation AddDropToMyDashboardButton($splashId: ID!, $dropId: ID!) {
  addDropToMyDashboard(splashId: $splashId, dropId: $dropId) {
    userAccount {
      id
      dashboardDrops(splashId: $splashId) {
        id
      }
    }
  }
}
    `;
export type AddDropToMyDashboardButtonMutationFn = Apollo.MutationFunction<AddDropToMyDashboardButtonMutation, AddDropToMyDashboardButtonMutationVariables>;

/**
 * __useAddDropToMyDashboardButtonMutation__
 *
 * To run a mutation, you first call `useAddDropToMyDashboardButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDropToMyDashboardButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDropToMyDashboardButtonMutation, { data, loading, error }] = useAddDropToMyDashboardButtonMutation({
 *   variables: {
 *      splashId: // value for 'splashId'
 *      dropId: // value for 'dropId'
 *   },
 * });
 */
export function useAddDropToMyDashboardButtonMutation(baseOptions?: Apollo.MutationHookOptions<AddDropToMyDashboardButtonMutation, AddDropToMyDashboardButtonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDropToMyDashboardButtonMutation, AddDropToMyDashboardButtonMutationVariables>(AddDropToMyDashboardButtonDocument, options);
      }
export type AddDropToMyDashboardButtonMutationHookResult = ReturnType<typeof useAddDropToMyDashboardButtonMutation>;
export type AddDropToMyDashboardButtonMutationResult = Apollo.MutationResult<AddDropToMyDashboardButtonMutation>;
export type AddDropToMyDashboardButtonMutationOptions = Apollo.BaseMutationOptions<AddDropToMyDashboardButtonMutation, AddDropToMyDashboardButtonMutationVariables>;
export const DropChartDocument = gql`
    query DropChart($splashId: ID!, $dropId: ID!, $startDate: DateTime!, $endDate: DateTime!, $suborganizationId: ID) {
  drop(id: $dropId) {
    id
    droplet {
      chart(
        splashId: $splashId
        startDate: $startDate
        endDate: $endDate
        suborganizationId: $suborganizationId
      ) {
        __typename
        labels
        datasets {
          label
          color
          data
        }
        xAxis {
          type
          name
        }
        yAxis {
          type
          name
        }
      }
    }
  }
}
    `;

/**
 * __useDropChartQuery__
 *
 * To run a query within a React component, call `useDropChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useDropChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDropChartQuery({
 *   variables: {
 *      splashId: // value for 'splashId'
 *      dropId: // value for 'dropId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      suborganizationId: // value for 'suborganizationId'
 *   },
 * });
 */
export function useDropChartQuery(baseOptions: Apollo.QueryHookOptions<DropChartQuery, DropChartQueryVariables> & ({ variables: DropChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DropChartQuery, DropChartQueryVariables>(DropChartDocument, options);
      }
export function useDropChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DropChartQuery, DropChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DropChartQuery, DropChartQueryVariables>(DropChartDocument, options);
        }
export function useDropChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DropChartQuery, DropChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DropChartQuery, DropChartQueryVariables>(DropChartDocument, options);
        }
export type DropChartQueryHookResult = ReturnType<typeof useDropChartQuery>;
export type DropChartLazyQueryHookResult = ReturnType<typeof useDropChartLazyQuery>;
export type DropChartSuspenseQueryHookResult = ReturnType<typeof useDropChartSuspenseQuery>;
export type DropChartQueryResult = Apollo.QueryResult<DropChartQuery, DropChartQueryVariables>;
export const RemoveDropFromMyDashboardButtonDocument = gql`
    mutation RemoveDropFromMyDashboardButton($splashId: ID!, $dropId: ID!) {
  removeDropFromMyDashboard(splashId: $splashId, dropId: $dropId) {
    userAccount {
      id
      dashboardDrops(splashId: $splashId) {
        id
      }
    }
  }
}
    `;
export type RemoveDropFromMyDashboardButtonMutationFn = Apollo.MutationFunction<RemoveDropFromMyDashboardButtonMutation, RemoveDropFromMyDashboardButtonMutationVariables>;

/**
 * __useRemoveDropFromMyDashboardButtonMutation__
 *
 * To run a mutation, you first call `useRemoveDropFromMyDashboardButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDropFromMyDashboardButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDropFromMyDashboardButtonMutation, { data, loading, error }] = useRemoveDropFromMyDashboardButtonMutation({
 *   variables: {
 *      splashId: // value for 'splashId'
 *      dropId: // value for 'dropId'
 *   },
 * });
 */
export function useRemoveDropFromMyDashboardButtonMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDropFromMyDashboardButtonMutation, RemoveDropFromMyDashboardButtonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDropFromMyDashboardButtonMutation, RemoveDropFromMyDashboardButtonMutationVariables>(RemoveDropFromMyDashboardButtonDocument, options);
      }
export type RemoveDropFromMyDashboardButtonMutationHookResult = ReturnType<typeof useRemoveDropFromMyDashboardButtonMutation>;
export type RemoveDropFromMyDashboardButtonMutationResult = Apollo.MutationResult<RemoveDropFromMyDashboardButtonMutation>;
export type RemoveDropFromMyDashboardButtonMutationOptions = Apollo.BaseMutationOptions<RemoveDropFromMyDashboardButtonMutation, RemoveDropFromMyDashboardButtonMutationVariables>;