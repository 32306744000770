import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverProps,
  useTheme,
} from '@mui/material';
import deepmerge from 'deepmerge';
import {
  usePopupState,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state/hooks';
import { useMemo } from 'react';
import { isNotNullish } from '../../utilities/is-nullish';

export type InfoPopoverButtonProps = IconButtonProps & {
  PopoverProps?: Omit<PopoverProps, keyof ReturnType<typeof bindPopover>>;
};

export function InfoPopoverButton(props: InfoPopoverButtonProps) {
  const { children, PopoverProps, ...iconButtonProps } = props;

  const { palette, breakpoints } = useTheme();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'infoPopover',
  });

  const PopoverPaperProps = useMemo(
    () =>
      deepmerge(PopoverProps?.PaperProps || {}, {
        sx: {
          maxWidth: breakpoints.values.sm,
          bgcolor: palette.grey[800],
          color: '#fff',
        },
      }),
    [PopoverProps?.PaperProps, breakpoints.values.sm, palette.grey],
  );

  return (
    <>
      <IconButton {...bindTrigger(popupState)} {...iconButtonProps}>
        <InfoIcon />
      </IconButton>
      <Popover
        {...PopoverProps}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        PaperProps={PopoverPaperProps}
        {...bindPopover(popupState)}
        onClick={({ target }) => {
          if (
            target instanceof HTMLAnchorElement ||
            (target instanceof HTMLElement && isNotNullish(target.closest('a')))
          ) {
            popupState.close();
          }
        }}>
        <Box px={3} py={2}>
          <IconButton
            sx={{ float: 'right', mt: -0.5, mr: -1 }}
            color="inherit"
            size="small"
            onClick={() => popupState.close()}>
            <CloseIcon fontSize="small" />
          </IconButton>
          {children}
        </Box>
      </Popover>
    </>
  );
}
