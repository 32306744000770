import {
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeProvider,
} from '@mui/material';
import deepmerge from 'deepmerge';
import React, { useMemo } from 'react';
import { appThemeOptions } from './app.theme-provider';

export const analyzePaletteColor: PaletteColor = {
  light: '#5ba4ef',
  main: '#0e76bc',
  dark: '#004b8b',
  contrastText: '#fff',
};

export function AnalyzeThemeProvider(
  props: React.PropsWithChildren<Record<never, never>>,
) {
  const { children } = props;

  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(appThemeOptions, {
          palette: {
            secondary: analyzePaletteColor,
          },
        }),
      ),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
