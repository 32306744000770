import { withAuthenticationRequired } from '@auth0/auth0-react';
import MenuOpenIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { IconButton, Stack, useTheme } from '@mui/material';
import { PrimaryDrawer } from '@repo/ui/components';
import { AppThemeProvider } from '@repo/ui/themes';
import { AppApolloProvider } from '@repo/graphql';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RootFallback } from '../root-fallback';
import { MainFallback } from './main-fallback';
import { MainAppBar } from './main.app-bar/main.app-bar';

export const MainRoute = withAuthenticationRequired(
  () => (
    <AppThemeProvider>
      <AppApolloProvider uri={import.meta.env.VITE_GRAPHQL_ENDPOINT}>
        <MainPresentation />
      </AppApolloProvider>
    </AppThemeProvider>
  ),
  { onRedirecting: () => <RootFallback /> },
);

function MainPresentation() {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const { breakpoints } = useTheme();

  return (
    <Stack minWidth={breakpoints.values.lg}>
      <PrimaryDrawer
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      />
      <MainAppBar
        leading={
          <IconButton
            edge="start"
            onClick={() => setDrawerIsOpen((value) => !value)}>
            {drawerIsOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        }
      />
      <React.Suspense fallback={<MainFallback />}>
        <Outlet />
      </React.Suspense>
    </Stack>
  );
}
