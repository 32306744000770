import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  Avatar,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import deepmerge from 'deepmerge';
import { useMemo } from 'react';

export type MenuAccordionProps = Pick<
  AccordionProps,
  'expanded' | 'onChange' | 'children'
> & {
  title: React.ReactNode;
  icon: React.ReactNode;
  AccordionSummaryProps?: AccordionSummaryProps;
};

export function MenuAccordion(props: MenuAccordionProps) {
  const {
    title,
    icon,
    children,
    AccordionSummaryProps: _,
    ...accordionProps
  } = props;

  const AccordionSummaryProps = useMemo(
    () =>
      deepmerge(props.AccordionSummaryProps ?? {}, {
        sx: {
          [`& > .${accordionSummaryClasses.content}`]: {
            alignItems: 'center',
            my: 1,
          },
          px: 1,
        },
      }),
    [props.AccordionSummaryProps],
  );

  return (
    <Accordion
      {...accordionProps}
      disableGutters
      square
      sx={{ bgcolor: grey[500], color: '#fff' }}>
      <AccordionSummary {...AccordionSummaryProps}>
        <Avatar sx={{ mr: 2 }}>{icon}</Avatar>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
}
