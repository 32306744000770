import { List, ListItemButton } from '@mui/material';
import { Link as RouterLink, useMatch } from 'react-router-dom';
import { analyzePaletteColor } from '../../themes/analyze.theme-provider';
import { AnalyzeIcon } from '../icons';
import { MenuAccordion, MenuAccordionProps } from './menu.accordion';

export type AnalyzeMenuAccordionProps = Omit<
  MenuAccordionProps,
  'title' | 'icon' | 'children' | 'AccordionSummaryProps'
>;

export function AnalyzeMenuAccordion(props: AnalyzeMenuAccordionProps) {
  const objectivesRouteMatch = useMatch('/analyze/objectives/:splashId/*');
  const flowRouteMatch = useMatch('/analyze/flows/:splashId/*');

  const splashId =
    objectivesRouteMatch?.params.splashId ?? flowRouteMatch?.params.splashId;

  const splashIdPathSegment = splashId ? `/${splashId}` : '';

  return (
    <MenuAccordion
      title="Analyze"
      icon={<AnalyzeIcon />}
      AccordionSummaryProps={{
        sx: {
          bgcolor: analyzePaletteColor.main,
          color: analyzePaletteColor.contrastText,
        },
      }}
      {...props}>
      <List>
        <ListItemButton
          component={RouterLink}
          to={`/analyze/objectives${splashIdPathSegment}`}>
          Objectives
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          to={`/analyze/flows${splashIdPathSegment}`}>
          Flow
        </ListItemButton>
      </List>
    </MenuAccordion>
  );
}
