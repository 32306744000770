import {
  ApolloClient,
  ApolloProvider as CoreApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

export type AppApolloProviderProps = React.PropsWithChildren<{
  uri: string;
}>;

export function AppApolloProvider(props: AppApolloProviderProps) {
  const { children, uri } = props;

  const { getAccessTokenSilently } = useAuth0();

  const client = useMemo(() => {
    const link = setContext(async (_, { headers }) => {
      const next = { headers: { ...headers } };

      try {
        const accessToken = await getAccessTokenSilently();

        next.headers.Authorization = `Bearer ${accessToken}`;
      } catch (e) {
        console.debug(e);
      }

      return next;
    }).concat(createHttpLink({ uri }));

    return new ApolloClient({
      cache: new InMemoryCache(),
      link,
      defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
    });
  }, [getAccessTokenSilently, uri]);

  return <CoreApolloProvider client={client}>{children}</CoreApolloProvider>;
}
