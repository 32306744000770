import {
  createTheme,
  CssBaseline,
  PaletteColor,
  ThemeProvider,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import React, { useMemo } from 'react';
import { appThemeOptions } from './app.theme-provider';

export const dashboardsPaletteColor: PaletteColor = {
  light: '#beffee',
  main: '#8cd5bc',
  dark: '#5ca38c',
  contrastText: '#fff',
};

export function DashboardsThemeProvider(
  props: React.PropsWithChildren<Record<never, never>>,
) {
  const { children } = props;

  const theme = useMemo(
    () =>
      createTheme(
        deepmerge(appThemeOptions, {
          palette: {
            secondary: dashboardsPaletteColor,
          },
        }),
      ),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
