import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  PaletteColor,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

export type MainMenuButtonProps<C extends React.ElementType> = Omit<
  ButtonBaseProps<C, { component?: C }>,
  'children'
> & {
  icon: React.ReactNode;
  label: string;
  paletteColor?: PaletteColor;
};

export function MainMenuButton<C extends React.ElementType = typeof ButtonBase>(
  props: MainMenuButtonProps<C>,
) {
  const { icon, paletteColor, label, sx, ...buttonBaseProps } = props;

  const { spacing, transitions } = useTheme();

  return (
    <ButtonBase
      {...buttonBaseProps}
      sx={{
        width: 136,
        height: 136,
        padding: spacing(1),
        color: paletteColor?.contrastText,
        backgroundColor: paletteColor?.main,
        borderColor: paletteColor?.contrastText,
        borderWidth: 0,
        borderStyle: 'solid',
        alignItems: 'flex-end',
        textAlign: 'center',
        opacity: buttonBaseProps.disabled ? 0.5 : 1,
        transition: transitions.create(['transform'], {
          duration: transitions.duration.shortest,
        }),
        '&:hover': {
          transform: 'scale(1.05)',
        },
        ...sx,
      }}>
      <Stack spacing={0}>
        <Box>{icon}</Box>
        <Typography variant="body2">{label}</Typography>
      </Stack>
    </ButtonBase>
  );
}
