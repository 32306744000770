import { List, ListItemButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { managePaletteColor } from '../../themes/manage.theme-provider';
import { ManageIcon } from '../icons';
import { MenuAccordion, MenuAccordionProps } from './menu.accordion';

export type ManageMenuAccordionProps = Omit<
  MenuAccordionProps,
  'title' | 'icon' | 'children' | 'AccordionSummaryProps'
>;

export function ManageMenuAccordion(props: ManageMenuAccordionProps) {
  return (
    <MenuAccordion
      title="Manage"
      icon={<ManageIcon />}
      AccordionSummaryProps={{
        sx: {
          bgcolor: managePaletteColor.main,
          color: managePaletteColor.contrastText,
        },
      }}
      {...props}>
      <List>
        <ListItemButton component={RouterLink} to="/manage/splashes">
          Splash
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/manage/metrics">
          Metrics
        </ListItemButton>
      </List>
    </MenuAccordion>
  );
}
