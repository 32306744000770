import * as Sentry from '@sentry/react';
import * as React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { MainMenuRoute } from './main/main-menu/main-menu.route';
import { MainRoute } from './main/main.route';
import { RootFallback } from './root-fallback';
import { RootRoute } from './root.route';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const Auth = React.lazy(() => import('@repo/auth'));
const ContentSpec = React.lazy(() => import('@repo/content-spec'));
const Analyze = React.lazy(() => import('@repo/analyze'));
const Manage = React.lazy(() => import('@repo/manage'));
const Dashboards = React.lazy(() => import('@repo/dashboards'));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <RootRoute />,
    children: [
      { path: '/auth/*', element: <Auth /> },
      { path: '/content-specs/*', element: <ContentSpec /> },
      {
        element: <MainRoute />,
        children: [
          { path: '/analyze/*', element: <Analyze /> },
          { path: '/manage/*', element: <Manage /> },
          { path: '/dashboards/*', element: <Dashboards /> },
          { path: '/', element: <MainMenuRoute /> },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} fallbackElement={<RootFallback />} />;
}

export default App;
