import { List, ListItemButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { dashboardsPaletteColor } from '../../themes/dashboards.theme-provider';
import { DashboardsIcon } from '../icons';
import { MenuAccordion, MenuAccordionProps } from './menu.accordion';

export type DashboardsMenuAccordionProps = Omit<
  MenuAccordionProps,
  'title' | 'icon' | 'children' | 'AccordionSummaryProps'
>;

export function DashboardsMenuAccordion(props: DashboardsMenuAccordionProps) {
  return (
    <MenuAccordion
      title="Dashboards"
      icon={<DashboardsIcon />}
      AccordionSummaryProps={{
        sx: {
          bgcolor: dashboardsPaletteColor.main,
          color: dashboardsPaletteColor.contrastText,
        },
      }}
      {...props}>
      <List>
        <ListItemButton component={RouterLink} to="/dashboards/my-dashboard">
          My Dashboard
        </ListItemButton>
        <ListItemButton component={RouterLink} to="/dashboards/my-benchmarks">
          My Benchmarks
        </ListItemButton>
      </List>
    </MenuAccordion>
  );
}
