import {
  AppBar,
  ButtonBase,
  Drawer,
  drawerClasses,
  DrawerProps,
  Toolbar,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { SplashmetricsLogo } from '../logo/splashmetrics.logo';
import { AnalyzeMenuAccordion } from './analyze-menu.accordion';
import { DashboardsMenuAccordion } from './dashboards-menu.accordion';
import { ManageMenuAccordion } from './manage-menu.accordion';

export const drawerWidth = 256;

export type PrimaryDrawerProps = Omit<DrawerProps, 'variant' | 'onClose'> & {
  onClose?: () => void;
};

const menuKeys = ['manage', 'analyze', 'dashboards'] as const;

type MenuKey = typeof menuKeys[number];

function isMenuKey(value?: string): value is MenuKey {
  return menuKeys.includes(value as MenuKey);
}

export function PrimaryDrawer(props: PrimaryDrawerProps) {
  const location = useLocation();

  const [expandedMenu, setExpandedMenu] = useState<
    typeof menuKeys[number] | null
  >(() => {
    const [pathnamePrefix] = location.pathname.replace(/^\//, '').split('/');

    return isMenuKey(pathnamePrefix) ? pathnamePrefix : null;
  });

  const onClose = useRef(props.onClose);

  useEffect(() => {
    onClose.current = props.onClose;
  }, [props.onClose]);

  useEffect(() => {
    if (location) {
      onClose.current?.();
    }
  }, [onClose, location]);

  return (
    <Drawer
      {...props}
      sx={{
        width: drawerWidth,
        [`& .${drawerClasses.paper}`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          border: 'none',
          background: grey[500],
        },
      }}
      variant="temporary">
      <AppBar
        position="sticky"
        color="default"
        sx={{ border: 'none', backgroundColor: 'background.default' }}>
        <Toolbar disableGutters sx={{ alignItems: 'unset' }}>
          <ButtonBase component={RouterLink} to="/" sx={{ flex: 1 }}>
            <SplashmetricsLogo height={56} />
          </ButtonBase>
        </Toolbar>
      </AppBar>
      <ManageMenuAccordion
        expanded={expandedMenu === 'manage'}
        onChange={(_, isExpanded) => {
          setExpandedMenu(isExpanded ? 'manage' : null);
        }}
      />
      <AnalyzeMenuAccordion
        expanded={expandedMenu === 'analyze'}
        onChange={(_, isExpanded) => {
          setExpandedMenu(isExpanded ? 'analyze' : null);
        }}
      />
      <DashboardsMenuAccordion
        expanded={expandedMenu === 'dashboards'}
        onChange={(_, isExpanded) => {
          setExpandedMenu(isExpanded ? 'dashboards' : null);
        }}
      />
    </Drawer>
  );
}
