import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import {
  Box,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  Zoom,
  useTheme,
} from '@mui/material';
import ChartJS, { ChartDataset } from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { hex } from 'chroma-js';
import { formatISOWithOptions } from 'date-fns/fp';
import { useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { DeepPick } from 'ts-essentials';
import { match } from 'ts-pattern';
import {
  BarChart,
  Drop,
  LineChart,
  Organization,
  PieChart,
  useDropChartLazyQuery,
} from '../../../../generated/graphql';
import { AddDropToMyDashboardButton } from './add-drop-to-my-dashboard.button/add-drop-to-my-dashboard.button';
import { DropInfoPopover } from './drop-info.popover';
import { RemoveDropFromMyDashboardButton } from './remove-drop-from-my-dashboard.button/remove-drop-from-my-dashboard.button';

ChartJS.defaults.maintainAspectRatio = false;
ChartJS.defaults.animation = false;
ChartJS.defaults.plugins.legend.position = 'bottom';
ChartJS.defaults.elements.line.tension = 0.4;
ChartJS.defaults.plugins.legend.labels.usePointStyle = true;
ChartJS.defaults.plugins.legend.labels.padding = 20;
ChartJS.defaults.plugins.legend.labels.boxHeight = 8;
ChartJS.defaults.plugins.legend.labels.pointStyle = 'circle';
ChartJS.defaults.scales.time.time.unit = 'day';
ChartJS.defaults.scales.time.time.minUnit = 'day';
ChartJS.defaults.scales.time.time.displayFormats.day = 'yyyy-MM-dd';
ChartJS.defaults.scales.time.time.tooltipFormat = 'yyyy-MM-dd';
ChartJS.defaults.scales.linear.beginAtZero = true;
ChartJS.defaults.elements.line.fill = true;
ChartJS.defaults.elements.line.borderWidth = 2;
ChartJS.defaults.elements.point.radius = 0;
ChartJS.defaults.elements.arc.borderWidth = 0;
ChartJS.defaults.elements.bar.borderWidth = 0;

export type DropLike = DeepPick<
  Drop,
  {
    id: true;
    name: true;
    description: true;
    dataSourceNames: true;
  }
> & {
  chart: DeepPick<
    LineChart | BarChart | PieChart,
    {
      __typename: true;
      xAxis: true;
      yAxis: true;
      datasets: Array<{
        color: never;
        data: never;
        label: never;
      }>;
      labels: true;
    }
  >;
};

export type DropChartProps<TDrop extends DropLike> = {
  isLoading?: boolean;
  subtitle?: string;
  splashId: string;
  startDate: number;
  endDate: number;
  suborganizationId: Organization['id'] | null;
  drop: TDrop;
  isOnDashboard?: boolean;
};

enum DropChartMode {
  droplet = 'droplet',
}

const formatDateForQuery = formatISOWithOptions({ representation: 'complete' });

export function DropChart<TDrop extends DropLike>(
  props: DropChartProps<TDrop>,
) {
  const {
    drop,
    splashId,
    startDate,
    endDate,
    suborganizationId,
    subtitle,
    isLoading: isLoading,
    isOnDashboard,
  } = props;

  const [mode, setMode] = useState<DropChartMode>();

  const { palette } = useTheme();

  const [execQuery, { data, loading: detailsLoading }] = useDropChartLazyQuery({
    variables: {
      dropId: drop.id,
      splashId,
      startDate: formatDateForQuery(startDate),
      endDate: formatDateForQuery(endDate),
      suborganizationId,
    },
  });

  const chart =
    mode === DropChartMode.droplet ? data?.drop.droplet.chart : drop.chart;

  const chartIsEmpty = useMemo(() => {
    if (!chart) {
      return true;
    }

    const { datasets, labels } = chart;

    const data = datasets.flatMap(({ data }) => data);

    if (data.length === 0 || labels.length === 0) {
      return true;
    }

    return undefined;
  }, [chart]);

  useEffect(() => {
    if (mode === DropChartMode.droplet && drop) {
      execQuery({ fetchPolicy: 'network-only' });
    }
  }, [execQuery, mode, drop]);

  return (
    <Paper>
      <Toolbar>
        <Box flex={1}>
          <Typography variant="body1" component="h2" fontWeight="bold">
            {drop.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {subtitle}
          </Typography>
        </Box>
        <Stack direction="row" mr={-1.5}>
          <Box height={34} width={34} position="relative">
            <Zoom in={isOnDashboard}>
              <RemoveDropFromMyDashboardButton
                splashId={splashId}
                dropId={drop.id}
                sx={{ position: 'absolute' }}
              />
            </Zoom>
            <Zoom in={!isOnDashboard}>
              <AddDropToMyDashboardButton
                splashId={splashId}
                dropId={drop.id}
                sx={{ position: 'absolute' }}
              />
            </Zoom>
          </Box>
          <DropInfoPopover drop={drop} />
        </Stack>
      </Toolbar>
      <Box px={2} paddingTop={2} height={400} display="flex">
        {chartIsEmpty && !isLoading && !detailsLoading ? (
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor={palette.grey[50]}>
            <Typography variant="body2" color="textSecondary">
              No data for this time period.
            </Typography>
          </Box>
        ) : (
          match(chart)
            .with({ __typename: 'BarChart' }, ({ labels, datasets }) => (
              <Chart
                type="bar"
                data={{
                  datasets: datasets.map(
                    ({ color, label, ...dataset }): ChartDataset<'pie'> => ({
                      ...dataset,
                      label: label ?? undefined,
                      borderColor: color ?? undefined,
                      backgroundColor: color?.map((c) =>
                        hex(c).alpha(0.67).hex(),
                      ),
                    }),
                  ),
                  labels,
                }}
                options={{
                  interaction: {
                    mode: 'index',
                    intersect: false,
                  },
                }}
              />
            ))
            .with({ __typename: 'PieChart' }, ({ labels, datasets }) => (
              <Chart
                type="pie"
                plugins={[ChartDataLabels]}
                options={{
                  plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'start',
                      offset: 15,
                      // offset: (ctx) =>
                      //   10 * (ctx.dataset.data.length - ctx.dataIndex),
                      borderRadius: 3,
                      backgroundColor: (ctx) => {
                        const { backgroundColor } = ctx.dataset;

                        if (
                          !backgroundColor ||
                          !(backgroundColor instanceof Array)
                        ) {
                          return null;
                        }

                        const currentColor = backgroundColor[ctx.dataIndex];

                        return typeof currentColor === 'string'
                          ? `hsl(from ${currentColor} h s l / 0.35)`
                          : null;
                      },
                      formatter: (value, ctx) => {
                        if (value === 0) {
                          return null;
                        }

                        const datapoints = ctx.chart.data.datasets[0].data;

                        const total = datapoints.reduce<number | undefined>(
                          (total, datapoint) => {
                            if (
                              typeof datapoint === 'number' &&
                              typeof total === 'number'
                            ) {
                              return total + datapoint;
                            }

                            return undefined;
                          },
                          0,
                        );

                        if (typeof total !== 'number') {
                          return value;
                        }

                        const percentage = (value / total) * 100;

                        return percentage.toFixed(1) + '%';
                      },
                      color: (ctx) => {
                        const { backgroundColor } = ctx.dataset;

                        if (
                          !backgroundColor ||
                          !(backgroundColor instanceof Array)
                        ) {
                          return palette.text.primary;
                        }

                        const currentColor = backgroundColor[ctx.dataIndex];

                        return typeof currentColor === 'string'
                          ? palette.getContrastText(currentColor)
                          : palette.text.primary;
                      },
                    },
                  },
                }}
                data={{
                  datasets: datasets.map(
                    ({ color, label, ...dataset }): ChartDataset<'pie'> => ({
                      ...dataset,
                      label: label ?? undefined,
                      borderColor: color ?? undefined,
                      backgroundColor: color?.map((c) =>
                        hex(c).alpha(0.67).hex(),
                      ),
                    }),
                  ),
                  labels,
                }}
              />
            ))
            .with(
              { __typename: 'LineChart' },
              ({ labels, datasets, xAxis, yAxis }) => (
                <Chart
                  type="line"
                  data={{
                    datasets: datasets
                      .map(
                        ({
                          color,
                          label,
                          ...dataset
                        }): ChartDataset<'pie'> => ({
                          ...dataset,
                          label: label ?? undefined,
                          borderColor: color ?? undefined,
                          backgroundColor: color?.map((c) =>
                            hex(c).alpha(0.67).hex(),
                          ),
                        }),
                      )
                      .reverse(),
                    labels,
                  }}
                  options={{
                    plugins: { legend: { reverse: true } },
                    interaction: {
                      mode: 'index',
                      intersect: false,
                    },
                    scales: {
                      x: {
                        type: xAxis?.type ?? undefined,
                        title: xAxis?.name
                          ? {
                              display: true,
                              text: xAxis?.name,
                            }
                          : undefined,
                      },
                      y: {
                        min: 0,
                        type: yAxis?.type ?? undefined,
                        title: yAxis?.name
                          ? {
                              display: true,
                              text: yAxis?.name,
                            }
                          : undefined,
                      },
                    },
                  }}
                />
              ),
            )
            .otherwise(() => null)
        )}
      </Box>
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <ToggleButtonGroup
          value={mode}
          exclusive
          onChange={(_, value) => setMode(value)}
          color="primary">
          <ToggleButton value={DropChartMode.droplet}>
            <WaterDropIcon sx={{ mr: 0.5 }} />
            View Droplets
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
    </Paper>
  );
}
