import { ImgHTMLAttributes } from 'react';
import helpIconSvg from './help.icon.svg';

export type HelpIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export function HelpIcon(props: HelpIconProps) {
  return <img {...props} src={helpIconSvg} alt="Help" />;
}
