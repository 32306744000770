import { ImgHTMLAttributes } from 'react';
import manageIconSvg from './manage.icon.svg';

export type ManageIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'alt'
>;

export function ManageIcon(props: ManageIconProps) {
  return <img {...props} src={manageIconSvg} alt="Manage" />;
}
