import { Container, Paper, Stack, Typography } from '@mui/material';
import {
  AnalyzeIcon,
  DashboardsIcon,
  HelpIcon,
  ManageIcon,
} from '@repo/ui/components';
import {
  analyzePaletteColor,
  dashboardsPaletteColor,
  managePaletteColor,
} from '@repo/ui/themes';
import { MainMenuButton } from './main-menu.button';
import { Link as RouterLink } from 'react-router-dom';

export function MainMenuRoute() {
  return (
    <Container sx={{ mx: 'unset', maxWidth: { xs: 'unset' } }}>
      <Paper sx={{ p: 5, pt: 4, pb: 8, my: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6" component="h1">
            Welcome to Splashmetrics!
          </Typography>
          <Stack direction="row" spacing={2}>
            <MainMenuButton
              component={RouterLink}
              to="/manage/splashes"
              icon={<ManageIcon height={86} />}
              label="Manage"
              paletteColor={managePaletteColor}
            />
            <MainMenuButton
              component={RouterLink}
              to="/analyze/objectives"
              icon={<AnalyzeIcon height={86} />}
              label="Analyze"
              paletteColor={analyzePaletteColor}
            />
            <MainMenuButton
              component={RouterLink}
              to="/dashboards/my-dashboard"
              icon={<DashboardsIcon height={86} />}
              label="Dashboards"
              paletteColor={dashboardsPaletteColor}
            />
            <MainMenuButton
              component="a"
              target="_blank"
              href="https://lifering.splashmetrics.com/"
              sx={{ borderWidth: 1 }}
              icon={<HelpIcon height={86} />}
              label="Help"
            />
          </Stack>
        </Stack>
      </Paper>
    </Container>
  );
}
