import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import AccordionSummary, {
  accordionSummaryClasses,
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

export const SummaryAccordionSummary = styled(
  (
    props: Omit<AccordionSummaryProps, 'expandIcon'> & {
      backgroundColor?: string;
      color?: string;
    },
  ) => <AccordionSummary {...props} expandIcon={<ExpandMoreIcon />} />,
  {
    shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'color',
  },
)(({ backgroundColor, color }) => ({
  backgroundColor,
  color,
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    color: 'inherit',
  },
}));
