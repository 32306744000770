import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { IconButton, LinearProgress } from '@mui/material';
import React from 'react';
import { AppThemeProvider } from '../../themes/app.theme-provider';
import { PrimaryAppBar } from '../primary.app-bar/primary.app-bar';

export type AppPlaceholderProps = React.PropsWithChildren<{
  loading?: boolean;
}>;

export function AppPlaceholder(props: AppPlaceholderProps) {
  const { children, loading } = props;

  return (
    <AppThemeProvider>
      <PrimaryAppBar
        leading={
          <IconButton edge="start" disabled>
            <MenuIcon />
          </IconButton>
        }
        trailing={
          <IconButton edge="end" disabled>
            <AccountCircleIcon />
          </IconButton>
        }
      />
      {loading ? <LinearProgress variant="indeterminate" /> : null}
      {children}
    </AppThemeProvider>
  );
}
