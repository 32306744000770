import {
  Box,
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import { useDebounce } from 'use-debounce';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useRemoveDropFromMyDashboardButtonMutation } from '../../../../../generated/graphql';
import React from 'react';

export type RemoveDropFromMyDashboardButtonProps = IconButtonProps & {
  splashId: string;
  dropId: string;
};

export const RemoveDropFromMyDashboardButton = React.forwardRef<
  HTMLButtonElement,
  RemoveDropFromMyDashboardButtonProps
>((props, ref) => {
  const { splashId, dropId, ...iconButtonProps } = props;

  const [execMutation, { loading }] =
    useRemoveDropFromMyDashboardButtonMutation();

  const [showProgress] = useDebounce(loading, 250);

  return (
    <Tooltip title="Remove from dashboard">
      <IconButton
        ref={ref}
        sx={{ position: 'relative', ...iconButtonProps.sx }}
        disabled={showProgress}
        {...iconButtonProps}
        onClick={(e) => {
          execMutation({ variables: { splashId, dropId } });
          iconButtonProps.onClick?.(e);
        }}>
        {showProgress ? (
          <Box
            sx={{ inset: 0 }}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <CircularProgress size={20} />
          </Box>
        ) : null}
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  );
});

RemoveDropFromMyDashboardButton.displayName =
  RemoveDropFromMyDashboardButton.name;
