import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { PrimaryAppBar, PrimaryAppBarProps } from '@repo/ui/components';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useMemo } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { P, match } from 'ts-pattern';
import {
  MainAppBarQueryResult,
  useMainAppBarQuery,
} from '../../../generated/graphql';

export type MainAppBarProps = Pick<PrimaryAppBarProps, 'leading'>;

export function MainAppBar(props: MainAppBarProps) {
  const queryResult = useMainAppBarQuery();

  const auth0 = useAuth0();

  const navigate = useNavigate();

  return (
    <MainAppBarPresentation
      {...props}
      queryResult={queryResult}
      isAuthenticated={auth0.isAuthenticated}
      userName={auth0.user?.name}
      isLoading={auth0.isLoading || queryResult.loading}
      onClickSignIn={() => {
        navigate('/auth/login');
      }}
      onClickSignOut={() => {
        navigate('/auth/logout');
      }}
    />
  );
}

export type MainAppBarPresentationProps = Pick<
  PrimaryAppBarProps,
  'leading'
> & {
  queryResult: MainAppBarQueryResult;
  userName: string | undefined;
  isLoading: boolean | undefined;
  isAuthenticated: boolean | undefined;
  onClickSignOut: () => void | undefined;
  onClickSignIn: () => void | undefined;
};

export function MainAppBarPresentation(props: MainAppBarPresentationProps) {
  const { leading, queryResult } = props;

  const { data, error } = queryResult;

  const authenticationError = useMemo(() => {
    if (Array.isArray(error)) {
      return error.find(
        ({ extensions }) => extensions?.code === 'UNAUTHENTICATED',
      );
    }

    if (error && 'graphQLErrors' in error) {
      return error.graphQLErrors.find(
        ({ extensions }) => extensions?.code === 'UNAUTHENTICATED',
      );
    }

    return undefined;
  }, [error]);

  const organization = data?.currentUser.organization;

  const accountMenuState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });

  return (
    <>
      <PrimaryAppBar
        leading={leading}
        brand={organization?.logoUrl}
        trailing={
          <>
            <Typography variant="caption">
              {match(props)
                .with(
                  { userName: P.not(P.nullish) },
                  ({ userName }) => `Hi ${userName}!`,
                )
                .with({ isLoading: true }, () => <Skeleton width={120} />)
                .otherwise(() => null)}
            </Typography>
            <IconButton
              sx={{ ml: 1 }}
              edge="end"
              {...bindTrigger(accountMenuState)}>
              <AccountCircleIcon />
            </IconButton>
            {match(props)
              .with({ isAuthenticated: true }, ({ onClickSignOut }) => (
                <Menu {...bindMenu(accountMenuState)}>
                  <MenuItem
                    component={RouterLink}
                    to="/dashboards/my-dashboard"
                    onClick={() => {
                      accountMenuState.close();
                    }}>
                    My Dashboard
                  </MenuItem>
                  <MenuItem
                    component="a"
                    target="_blank"
                    href="https://lifering.splashmetrics.com/">
                    Help
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => onClickSignOut?.()}>
                    Log Out
                  </MenuItem>
                </Menu>
              ))
              .with(
                { isAuthenticated: false, isLoading: false },
                ({ onClickSignIn }) => (
                  <Menu {...bindMenu(accountMenuState)}>
                    <MenuItem onClick={() => onClickSignIn?.()}>
                      Log In
                    </MenuItem>
                  </Menu>
                ),
              )
              .otherwise(() => null)}
          </>
        }
      />
      <Dialog
        open={Boolean(authenticationError) && Boolean(props.isAuthenticated)}>
        <DialogTitle>Unauthorized</DialogTitle>
        <DialogContent>
          It looks like you aren&apos;t currently associated with any
          organization. Please contact <Link>help@splashmetrics.com</Link> for
          assistance!
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClickSignOut?.()}>Sign out</Button>
          <Button onClick={() => window.location.reload()} variant="contained">
            Retry
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
