import {
  Box,
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import { useDebounce } from 'use-debounce';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAddDropToMyDashboardButtonMutation } from '../../../../../generated/graphql';
import React from 'react';

export type AddDropToMyDashboardButtonProps = IconButtonProps & {
  splashId: string;
  dropId: string;
};

export const AddDropToMyDashboardButton = React.forwardRef<
  HTMLButtonElement,
  AddDropToMyDashboardButtonProps
>((props, ref) => {
  const { splashId, dropId, ...iconButtonProps } = props;

  const [execMutation, { loading }] = useAddDropToMyDashboardButtonMutation();

  const [showProgress] = useDebounce(loading, 250);

  return (
    <Tooltip title="Float to dashboard">
      <IconButton
        ref={ref}
        sx={{ position: 'relative', ...iconButtonProps.sx }}
        disabled={showProgress}
        {...iconButtonProps}
        onClick={(e) => {
          execMutation({ variables: { splashId, dropId } });
          iconButtonProps.onClick?.(e);
        }}>
        {showProgress ? (
          <Box
            sx={{ inset: 0 }}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center">
            <CircularProgress size={20} />
          </Box>
        ) : null}
        <AddCircleOutlineIcon />
      </IconButton>
    </Tooltip>
  );
});

AddDropToMyDashboardButton.displayName = AddDropToMyDashboardButton.name;
