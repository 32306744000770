import { styled } from '@mui/material';
import AccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';

export const SummaryAccordionDetails = styled(
  (props: AccordionDetailsProps & { borderColor?: string }) => (
    <AccordionDetails {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== 'borderColor' },
)(({ borderColor }) => ({
  borderWidth: 16,
  borderStyle: 'solid',
  borderColor,
  borderTop: 'none',
}));
